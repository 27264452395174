/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/quotes */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  pct: number;
  fundingGoalMin: number;
  value: number;
  isTable?: boolean
}
const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: (props: StyleProps) => props.isTable ? 18 : 20,
    width: '100%',
    backgroundColor: (props: StyleProps) => props.pct > 100
      ? theme.palette.positive.main
      : theme.palette.primary.extralight,
    borderRadius: '8px',
    margin: 50,
    marginLeft: 0,
  },
  filler: {
    height: (props: StyleProps) => (props.pct > 2 ? '100%' : '80%'),
    width: (props: StyleProps) => `${props.pct}%`,
    backgroundColor: (props: StyleProps) => (props.pct >= 100 || props.value > props.fundingGoalMin)
      ? theme.palette.positive.main
      : theme.palette.primary.main,
    borderRadius: 'inherit',
    textAlign: 'right',
  },
  label: {
    padding: 5,
    fontWeight: 'bold',
    color: theme.palette.greyScale.superlight,
  },
}));

interface Props {
  value: number;
  goal: number;
  fundingGoalMin: number ;
  isTable?: boolean
}

const defaultProps = {
  isTable: false,
};

const Timeline2 = ({
  value, goal, fundingGoalMin, isTable,
}: Props) => {
  const pct = 100 * (value / goal);
  const classes = useStyles({
    pct, fundingGoalMin, value, isTable,
  });

  if (isTable ? pct < 30 : pct < 6) {
    return (
      <div className={classes.container}>
        <div className={classes.filler} />
        <span style={{ fontWeight: 'bold', padding: 5 }}>
          {`${Math.round(pct)}%`}
        </span>
      </div>
    );
  }
  if (pct > 100) {
    return (
      <div className={classes.container}>
        <span className={classes.label}>OVERSUBSCRIBED</span>
        <span className={classes.label} style={{ justifySelf: 'flex-end' }}>
          {`${Math.round(pct)}%`}
        </span>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.filler}>
        <span className={classes.label}>{`${Math.round(pct)}%`}</span>
      </div>
    </div>
  );
};

Timeline2.defaultProps = (defaultProps as unknown) as Partial<Props>;

export default Timeline2;
