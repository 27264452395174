/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import NextLink from 'next/link';
import clsx from 'clsx';

// MUI functions
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Link from '@material-ui/core/Link';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';

import { useDispatch, useSelector } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { unwrapResult } from '@reduxjs/toolkit';
import useCookies from 'react-cookie/cjs/useCookies';

import { NAVIGATION_ITEMS } from '@/constants';
import { fetchNewNotifications } from '@/features/notifications/api/slice';
import { RootState } from '@/store/rootReducer';
import { signOut } from '@/features/auth/api/slice';
import { AppDispatch } from '@/store/store';
import ButtonLink from '@/components/page-components/button-link';
import StyledMenu from '../ui-components/Menu';
import UserAvatar from '../ui-components/UserAvatar';
import GlobalSearch from '../global-search/GlobalSearch';
import SearchResults from '../global-search/SearchResults';
import { LogoWixdom } from '../ui-components/SVGIcons';

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: '#fff',
    [theme.breakpoints.up('md')]: {
      minHeight: '65px',
    },
  },
  linkButtonHome: {
    display: 'flex',
    alignItems: 'center',
  },
  flexElement: {
    flexGrow: 1,
  },
  navigationDiv: {
    bosmhadow: '4px 4px 5px 0px rgba(0,0,0,0.3)',
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 5000,
    padding: theme.spacing(1),
  },
  navigationDivFlexBoxDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  navigationDivFlexBoxDivItem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  whiteText: {
    color: '#fff',
  },
  navbtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    color: theme.palette.greyScale.dark,
  },
  navBtnActive: {
    color: theme.palette.primary.main,
  },
  navButtonTypography: {
    marginTop: theme.spacing(0.5),
  },
  list: {
    width: 250,
  },
  cleanLink: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  username: {
    color: theme.palette.greyScale.dark,
    fontWeight: 500,
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
  },
  userBadgeFlexBox: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  drawerDivider: {
    borderBottomColor: theme.palette.secondary.main,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logoutBtn: {
    textTransform: 'none',
    marginLeft: theme.spacing(1.5),
  },
  unstyledBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    marginTop: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover > *:last-child': {
      textDecoration: 'underline',
    },
    marginRight: theme.spacing(2),
  },
  youButtonCaption: {},

  searchFull: {
    backgroundColor: 'white',
    margin: 0,
    marginRight: '0',
    borderRadius: '0px',
  },
  toolbar: {
    paddingRight: '0',
  },

  searchbar: {
    marginLeft: theme.spacing(5),
    flex: 2,
  },

  notificationBannerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navLink: {
    width: '140px',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  notificationBannerText: {
    '& a': {
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: 'underline',
    },
  },
}));

interface Props {
  activeKey?: string;
}

const defaultProps = {
  activeKey: '',
};

const Appbar = ({ activeKey }: Props) => {
  const { auth } = useSelector((state: RootState) => state);

  const { t } = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles();
  const router = useRouter();
  const { push } = useRouter();

  // mobile (side drawer)
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  // calculate active appbar button
  const getActive = useMemo(
    () => (slug: string) => {
      // we can't use startsWith if we're in the "/" path
      // because it would always return true
      if (slug === '/') {
        if (activeKey === slug) {
          return true;
        }
      } else if (activeKey?.startsWith(slug)) {
        return true;
      }
      return false;
    },
    [activeKey],
  );

  // load notifications
  const dispatch = useDispatch() as AppDispatch;
  const { new_count: notificationsCount, fetched } = useSelector(
    (state: RootState) => state.notifications,
  );

  useEffect(() => {
    if (!fetched && auth.user) {
      dispatch(fetchNewNotifications());
    }
  }, [auth.user, fetched]);

  /* handle user menu */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /* SignOut */
  const [cookies, setCookie, removeCookie] = useCookies();
  const handleMenuCloseLogout = () => {
    const doSignOut = async () => {
      dispatch(signOut())
        .then(unwrapResult)
        .then(() => {
          // remove cookie/localStorage
          localStorage.removeItem('token');
          if (cookies.token) {
            removeCookie('token', {
              path: '/',
              expires: new Date('2099-12-31'),
              secure: true,
              sameSite: 'lax',
            });
          }
          push('/');
        });
    };
    setAnchorEl(null);
    doSignOut();
  };

  const handleMenuCloseNav = (navigationTarget: string) => {
    setAnchorEl(null);
    push(navigationTarget);
  };

  const menuId = 'primary-search-account-menu';
  // state mgmt for global search
  const [active, setActive] = useState(false);
  const [value, setValue] = useState('');

  /* Render "You" Menu */
  const renderMenu = (
    <>
      {/* If user exits in the state */}
      {auth.user && (
        <StyledMenu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <ListItem>
            <ListItemIcon>
              {/* TODO Show avatar img */}
              <UserAvatar
                firstName={auth.user.first_name}
                lastName={auth.user.last_name}
                badgeColor={auth.user.color || theme.palette.primary.main}
                imageUrl={auth.user?.avatar || undefined!}
              />
            </ListItemIcon>
            <ListItemText
              primary={`${auth.user.first_name} ${auth.user.last_name}`}
              secondary={`${auth.user.email}`}
            />
          </ListItem>
          <Divider />
          {/* Settings */}
          <ListItem button onClick={() => handleMenuCloseNav('/settings')}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('appbar.settings')} />
          </ListItem>
          {/* SignOut */}
          <ListItem button onClick={handleMenuCloseLogout}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('appbar.session.close_title')} />
          </ListItem>
        </StyledMenu>
      )}
    </>
  );

  const loggedAppbar = (
    <>
      {' '}
      {/* menu items */}
      {sm ? (
        <>
          <div className={classes.flexElement} />
          <GlobalSearch
            active={active}
            setActive={setActive}
            value={value}
            setValue={setValue}
            variant="sm"
          />
        </>
      ) : (
        <>
          <GlobalSearch
            active={active}
            setActive={setActive}
            value={value}
            setValue={setValue}
            variant="lg"
          />

          {!active && (
            <>
              <div className={classes.flexElement} />
              <div className={classes.navigationDivFlexBoxDiv}>
                {NAVIGATION_ITEMS.filter((x) => x.showsIn.includes('APPBAR')).map((navItem) => {
                  const isActive = getActive(navItem.slug);
                  return (
                    <NextLink key={navItem.slug} href={navItem.slug} passHref>
                      <Link
                        href={navItem.slug}
                        className={clsx(
                          classes.navbtn,
                          isActive && classes.navBtnActive,
                        )}
                      >
                        {navItem.i18nkey === 'NOTIFICATIONS'
                        && notificationsCount > 0 ? (
                          <Badge
                            badgeContent={notificationsCount}
                            color="primary"
                            overlap="circle"
                          >
                            {navItem.icon}
                          </Badge>
                          ) : (
                            navItem.icon
                          )}
                        <Typography
                          color="inherit"
                          variant="caption"
                          className={classes.navButtonTypography}
                        >
                          {t(`appbar.navItems.${navItem.i18nkey}` as const)}
                        </Typography>
                      </Link>
                    </NextLink>
                  );
                })}
              </div>
              {/* User menu */}
              <button
                type="button"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                className={clsx(classes.unstyledBtn, classes.navbtn)}
              >
                {/* Show initials and user color */}
                <UserAvatar
                  firstName={auth.user?.first_name!}
                  lastName={auth.user?.last_name!}
                  badgeColor={auth.user?.color || theme.palette.primary.main}
                  imageUrl={auth.user?.avatar || undefined!}
                  size={4.5}
                />
                <Typography
                  color="inherit"
                  variant="caption"
                  className={clsx(
                    classes.youButtonCaption,
                    classes.navButtonTypography,
                  )}
                >
                  {/* TODO translation */}
                  {t('appbar.navItems.you')}
                </Typography>
              </button>
              {renderMenu}
            </>
          )}
        </>
      )}
      )
    </>
  );

  const noLoggedAppbar = (
    <>
      {sm ? (
        <>
          <div className={classes.flexElement} />
          {/* <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon color="secondary" />
        </IconButton> */}
        </>
      ) : (
        <>
          <div className={classes.flexElement} />
          <div className={classes.navigationDivFlexBoxDiv}>
            {/* CTA buttons on desktop */}
            <div className={classes.navLink}>
              <ButtonLink
                href="/user/login"
                appearance="primaryOutlined"
                fullWidth
              >
                {t('appbar.navItems.login' as const)}
              </ButtonLink>
            </div>
            <div className={classes.navLink}>
              <ButtonLink href="/user/signup" appearance="featured" fullWidth>
                {t('appbar.navItems.signup' as const)}
              </ButtonLink>
            </div>
          </div>
        </>
      )}
    </>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          {(!sm || !active) && (
            <NextLink href={auth.user ? '/feed' : '/'} passHref>
              <Link className={classes.linkButtonHome} href="/">
                <LogoWixdom
                  color={theme.palette.primary.main}
                  text={theme.palette.primary.main}
                />
              </Link>
            </NextLink>
          )}
          {auth.user ? loggedAppbar : noLoggedAppbar}
        </Toolbar>
      </AppBar>
      {active && <SearchResults active={active} value={value} />}
    </>
  );
};

Appbar.defaultProps = (defaultProps as unknown) as Partial<Props>;

export default Appbar;
