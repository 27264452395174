/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useTranslation } from 'next-i18next';

// MUI components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';

import { useRouter } from 'next/router';
import { DialogTitle } from './ConfirmationDialog';
import availableLanguages from '../../i18n/availableLanguages';

type LanguageDialogProps = {
  onClose: () => void;
  open: boolean;
};

const LanguageDialog = ({ onClose, open }: LanguageDialogProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const changeLanguage = (code: string) => {
    onClose();
    router.push(router.asPath, undefined, { locale: code });
  };

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="change-dialog-title" open={open}>
      <DialogTitle
        id="change-dialog-title"
        onClose={onClose}
      >
        {t('change_language_dialog_title')}
      </DialogTitle>
      <List>
        {availableLanguages.map((language) => (
          <ListItem key={language.code} button onClick={() => changeLanguage(language.code)}>
            <ListItemText primary={language.name} />
          </ListItem>
        ))}
      </List>

    </Dialog>
  );
};

export default LanguageDialog;
