import React, { ReactNode } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

// eslint-disable-next-line react/display-name
export const Transition = React.forwardRef((
  // eslint-disable-next-line react/require-default-props
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialogWindow: {
    minWidth: '350px',
  },
  price: {
    color: theme.palette.primary.main,
  },
  subheader: {
    color: theme.palette.primary.main,
    fontWeight: 300,
  },
  inputfield: {
    width: '90%',
    minWidth: '80px',
  },
  offeringInfoGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  offeringInfoLabel: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface DialogTitleProps {
  id?: string
  children: ReactNode
  onClose: () => void
}

const defaultDialogProps = {
  id: '',
};

export const DialogTitle: React.FC<DialogTitleProps> = ((props: DialogTitleProps) => {
  const {
    children, onClose, id, ...other
  } = props;
  const classes = useDialogStyles();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogTitle disableTypography className={classes.root} id={id} {...other}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={10}>
          <Typography variant="h6" color="inherit">{children}</Typography>
        </Grid>
        <Grid item xs={2}>
          {onClose ? (
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon color="inherit" />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});

DialogTitle.defaultProps = defaultDialogProps as unknown as Partial<DialogTitleProps>;

type ConfirmationDialogPropTypes = {
  open: boolean;
  handleDismiss: (...args: any[]) => any;
  handleConfirm: (...args: any[]) => any;
  message: string;
  title: string;
  confirmOptionText: string;
  dismissOptionText: string;
};

const ConfirmationDialog = ({
  open,
  handleDismiss,
  handleConfirm,
  message,
  title,
  confirmOptionText,
  dismissOptionText,
}: ConfirmationDialogPropTypes) => {
  const classes = useStyles();

  return (

    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="lg"
      className={classes.dialogWindow}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle onClose={handleDismiss}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismiss}>
          {dismissOptionText}
        </Button>
        <Button onClick={handleConfirm}>
          {confirmOptionText}
        </Button>
      </DialogActions>
    </Dialog>

  );
};

export default ConfirmationDialog;
