const availableLanguages = [
  {
    code: 'es',
    fullCode: 'es-ES',
    name: 'Español',
  },
  {
    code: 'en',
    fullCode: 'en-GB',
    name: 'English',
  },
  {
    code: 'de',
    fullCode: 'de-DE',
    name: 'Deutsch',
  },
];

export default availableLanguages;
