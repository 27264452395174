/*
    This file contains app-wide constants that don't belong to
    the theme and can't be environment variables.
*/
import React from 'react';

// Icons
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';

// navigation entries
type NavigationEntry = {
  slug: string
  i18nkey: 'DASHBOARD' | 'MARKETS' | 'COMMUNITY' | 'PORTFOLIO' | 'NOTIFICATIONS' | 'MORE'
  icon: React.ReactNode
  showsIn: Partial<['BOTTOM_NAV', 'APPBAR']>
};

export const NAVIGATION_ITEMS: NavigationEntry[] = [
  {
    slug: '/feed',
    i18nkey: 'DASHBOARD',
    icon: <HomeIcon fontSize="large" color="inherit" />,
    showsIn: ['BOTTOM_NAV', 'APPBAR'],
  },
  // {
  //   slug: '/portfolio',
  //   i18nkey: 'PORTFOLIO',
  //   icon: <PieChartIcon fontSize="large" color="inherit" />,
  //   showsIn: ['BOTTOM_NAV', 'APPBAR'],
  // },
  {
    slug: '/markets/offerings',
    i18nkey: 'MARKETS',
    icon: <BubbleChartIcon fontSize="large" color="inherit" />,
    showsIn: ['BOTTOM_NAV', 'APPBAR'],
  },
  /*  {
    slug: '/community',
    i18nkey: 'COMMUNITY',
    icon: <PeopleIcon fontSize="large" color="inherit" />,
    showsIn: ['BOTTOM_NAV', 'APPBAR'],
  },
  {
    slug: '/notifications',
    i18nkey: 'NOTIFICATIONS',
    icon: <NotificationsIcon fontSize="large" color="inherit" />,
    showsIn: ['BOTTOM_NAV', 'APPBAR'],
  }, */
  {
    slug: '/menu',
    i18nkey: 'MORE',
    icon: <MenuIcon fontSize="large" color="inherit" />,
    showsIn: ['BOTTOM_NAV'],
  },
];

// global debounce values
export const TEXT_INPUT_DEBOUNCE = 1000;
export const FILTERS_DEBOUNCE = 1000;

export const DEFAULT_OFFER_COVER_IMAGES = {
  xs: '',
  md: '',
  lg: '',
};

export const PAGE_TRANSITIONS = {
  hidden: { opacity: 0, y: -70, x: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
};
