import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

interface StyleProps {
  badgeColor: string
  strokeColor: string
  size: number
}

const useStyles = makeStyles((theme) => ({
  badgeSizeResponsive: {
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(8),
      width: theme.spacing(8),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(5),
      width: theme.spacing(5),
      marginRight: theme.spacing(1),
    },
  },
  badgeBackgroundColor: {
    backgroundColor: (props: StyleProps) => (
      props.badgeColor),
  },
  badgeSize: {
    height: (props: StyleProps) => theme.spacing(props.size),
    width: (props: StyleProps) => theme.spacing(props.size),
  },
  stroke: {
    borderStyle: 'solid',
    borderWidth: '8px',
    borderColor: (props: StyleProps) => (
      props.strokeColor),
  },
}));

interface UserAvatarProps {
  firstName: string
  lastName: string
  badgeColor: string
  strokeColor?: string
  sizeControlled?: boolean
  imageUrl?: string
  size?: number
}

const defaultProps = {
  strokeColor: '',
  sizeControlled: false,
  imageUrl: null,
  size: 6,
};

const UserAvatar = ({
  firstName, lastName, badgeColor, strokeColor, sizeControlled, imageUrl, size = 6,
}: UserAvatarProps) => {
  const stylesObject = {
    badgeColor,
    strokeColor: strokeColor || '',
    size,
  };
  const classes = useStyles(stylesObject);

  if (firstName && lastName && badgeColor) {
    return (
      <Avatar
        className={clsx(
          sizeControlled ? classes.badgeSizeResponsive : '',
          strokeColor ? classes.stroke : '',
          !imageUrl ? classes.badgeBackgroundColor : '',
          classes.badgeSize,
        )}
      >
        {imageUrl
          ? (
            <img
              src={imageUrl}
              width="100%"
              alt={`${firstName} ${lastName}`}
            />
          )
          : `${firstName[0]}${lastName[0]}`}
      </Avatar>
    );
  }
  return null;
};

UserAvatar.defaultProps = defaultProps as unknown as Partial<UserAvatarProps>;

export default UserAvatar;
