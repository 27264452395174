// React, Next, Libraries
import React, { ChangeEvent, useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  RadioProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Local imports
import theme from 'src/theme';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '@/hooks/useDebounce';
import { RootState } from '@/store/rootReducer';
import { fetchSearchResults } from '@/features/search/slice';
import OfferCard from '@/components/offering-card/OfferingCard';
import { TEXT_INPUT_DEBOUNCE } from '@/constants';
import ButtonLink from '../links/ButtonLink';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(255,255,255,0.7)',
    backdropFilter: 'blur(5px)',
    position: 'fixed',
    width: '100%',
    zIndex: 1099,
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 4px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 64px)',
    },
  },
  textinput: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  textinputElement: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  radio: {
    backgroundColor: '#FAFAFA',
  },
  loadingDiv: {
    margin: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingSpinner: {
    marginBottom: theme.spacing(1),
  },
  search: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    marginTop: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
  },
}));

interface Props {
  value: string,
  active: boolean
  setActive: (arg0: boolean) => void
}

const defaultProps = {
};

const BlueRadio = withStyles({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
// eslint-disable-next-line react/jsx-props-no-spreading
})((props: RadioProps) => <Radio color="default" {...props} />);

const SearchedResults = ({ value, active, setActive }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation(['components', 'common']);
  const debounce = useDebounce(value, TEXT_INPUT_DEBOUNCE);
  const { search } = useSelector((state:RootState) => state.search);
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState<'all' | 'company'| 'platform'>('all');

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    newValue: string,
  ) => {
    setSelectedType(newValue as 'all' | 'company'| 'platform');
  };

  useEffect(() => {
    if (debounce) {
      dispatch(fetchSearchResults({ searchterm: debounce, selectedType }));
    }
  }, [debounce, selectedType]);

  return (
    <>
      {(active && debounce) && (
        <>
          <div className={classes.root}>
            <Container className={classes.radio}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="searches"
                  value={selectedType}
                  onChange={handleChange}
                  className={classes.textinput}
                >
                  <FormControlLabel
                    value="all"
                    control={<BlueRadio />}
                    label={t('common:appbar.search.all')}
                    className={classes.textinputElement}
                  />
                  <FormControlLabel
                    value="company"
                    control={<BlueRadio />}
                    label={t('common:appbar.search.company')}
                    className={classes.textinputElement}
                  />
                  <FormControlLabel
                    value="platform"
                    control={<BlueRadio />}
                    label={t('common:appbar.search.platform')}
                    className={classes.textinputElement}
                  />
                </RadioGroup>
              </FormControl>
            </Container>
            <Container>
              {search.isLoading && (
              <div className={classes.loadingDiv}>
                <CircularProgress className={classes.loadingSpinner} />
                <Typography color="textSecondary" variant="body2">
                  {t('components:loadingScreen.loading')}
                </Typography>
              </div>
              )}
              <Grid container className={classes.search}>
                {(!search.isLoading && search.data)
              && search.data.results.slice(0, 3).map((result, index) => (
                <Grid key={result.slug} item xs={12} md={4}>
                  <OfferCard offering={result} type="search" last={(index + 1) % 3 === 0} setActive={setActive} />
                </Grid>

              ))}
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} md={4}>
                  {/* {(!search.isLoading && search.data.results.length > 0)
              && (
              <ButtonLink
                href="/markets/offerings"
                color="primary"
                variant="contained"
                fullWidth
                className={classes.button}
              >
                {t('common:appbar.search.button')}
              </ButtonLink>
              )} */}
                  {(!search.isLoading && search.data.results.length < 1)
              && (
              <Alert icon={false} severity="info" className={classes.text}>
                <Typography variant="h5">
                  {t('common:appbar.search.results', { term: debounce })}
                </Typography>
              </Alert>
              )}
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

SearchedResults.defaultProps = defaultProps as unknown as Partial<Props>;

export default SearchedResults;
