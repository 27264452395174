// Next, React, Libraries
import React, { useEffect, useRef } from 'react';

// Material UI
import { useTheme } from '@material-ui/core/styles';
import {
  InputAdornment,
  IconButton,
} from '@material-ui/core';

// icons
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

// local imports
import { useTranslation } from 'next-i18next';
import SearchBar from './SearchBar';

interface Props {
  active: boolean
  setActive: (arg0: boolean) => void
  value: string
  setValue: (arg0: string) => void
  variant: 'sm' | 'lg'
}

const defaultProps = {

};

const GlobalSearch = ({
  active, setActive, value, setValue, variant,
}: Props) => {
  const input = useRef<null | HTMLInputElement>(null);

  const handleClose = () => {
    setActive(false);
    input.current?.blur();
    setValue('');
  };
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [active]);

  // keyboard shortcuts
  React.useEffect(() => {
    const handleKeyDown = (nativeEvent: any) => {
      if (nativeEvent.defaultPrevented) {
        return;
      }

      if (nativeEvent.key === 'Escape' && document.activeElement === input.current) {
        input.current?.blur();
        setActive(false);
        setValue('');
        return;
      }

      const matchMainShortcut = (
        nativeEvent.ctrlKey || nativeEvent.metaKey) && nativeEvent.key === 'k';
      const matchNonkeyboardNode = ['INPUT', 'SELECT', 'TEXTAREA']
        .indexOf(document.activeElement?.tagName || '0') === -1
        && !(document.activeElement as HTMLInputElement).isContentEditable;

      if (matchMainShortcut && matchNonkeyboardNode) {
        nativeEvent.preventDefault();
        input.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const macOS = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  const theme = useTheme();
  const { t } = useTranslation('common');

  switch (variant) {
    case 'sm':
      return (
        <>
          {active ? (
            <>
              <SearchBar
                id="global-search-mobile"
                placeholder={t('appbar.search.search')}
                fullWidth
                autoFocus
                value={value}
                inputRef={input}
                onFocus={() => {
                  setActive(true);
                }}
                onClick={() => {
                  setActive(true);
                }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setValue(e.target.value);
                }}
              />
              <IconButton
                onClick={handleClose}
                style={{
                  position: 'absolute', right: 20,
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          )
            : (
              <IconButton onClick={() => setActive(true)}>
                <SearchIcon />
              </IconButton>
            )}
        </>
      );

    default:
      return (
        <>
          <SearchBar
            id="global-search-desktop"
            placeholder={t('appbar.search.search')}
            value={value}
            inputRef={input}
            endAdornment={(
              <InputAdornment position="end" style={{ marginRight: theme.spacing(2) }}>
                {!active && (
                <span>
                  {macOS ? '⌘' : 'Ctrl+'}
                  {t('appbar.search.k')}
                </span>
                )}
              </InputAdornment>
      )}
            onFocus={() => {
              setActive(true);
            }}
            onClick={() => {
              setActive(true);
            }}
            onChange={
              (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setValue(e.target.value);
              }
            }
            active={active}
          />
          {active && (
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute', right: 20,
            }}
          >
            <CloseIcon />
          </IconButton>
          )}
        </>
      );
  }
};

GlobalSearch.defaultProps = defaultProps as unknown as Partial<Props>;

export default GlobalSearch;
