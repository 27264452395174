/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Link, { LinkProps } from 'next/link';
import { ButtonProps } from '@material-ui/core/Button';
import { CardActionArea } from '@material-ui/core';

export type ButtonLinkProps = Omit<ButtonProps, 'href' | 'classes'> &
Pick<LinkProps, 'href' | 'as' | 'prefetch'>;

interface Props{
  card: boolean
  onCallback?: () => void
  md?: boolean
  type?: 'search'|'watchlist'
  setActive?: (arg0:boolean) => void
}

const defaultProps = {

};

const CardLink = React.forwardRef<ButtonLinkProps, any>(
  ({
    onCallback, md, type, setActive, href, as, prefetch, ...props
  }: ButtonLinkProps & Props, ref: React.ForwardedRef<any>) => (
    <Link href={href} as={as} prefetch={prefetch} passHref>
      <CardActionArea
        component="a"
        ref={ref}
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          if (md) {
            e.preventDefault();
          }
          if (type === 'search') {
            if (setActive) {
              setActive(false);
            }
          }
          if (onCallback) {
            onCallback();
          }
        }}
      />
    </Link>
  ),
);

CardLink.displayName = 'CardLink';

CardLink.defaultProps = defaultProps as unknown as Partial<Props>;

export default CardLink;
