/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { InputAdornment, InputBase } from '@material-ui/core';
import { InputBaseProps } from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {

  },

  searchInput: {
    backgroundColor: '#E0E2EB',
    borderRadius: '4px',
    padding: theme.spacing(0.5),
  },
  searchFocused: {
    backgroundColor: 'white',
  },
  searchbar: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      flex: 1,
    },
  },
  shortcut: {
    color: theme.palette.primary.main,
  },
}));

interface Props extends InputBaseProps {
  active?: boolean
}

const defaultProps = {
  active: false,
};

const SearchBar = ({ active, ...props }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <InputBase
        className={classes.searchbar}
        autoComplete="off"
        onKeyDown={(e) => e.stopPropagation()}
        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon style={{
              fill: theme.palette.primary.main,
              paddingLeft: theme.spacing(0.5),
            }}
            />
          </InputAdornment>
                    )}
        classes={{
          root: classes.searchInput,
          focused: classes.searchFocused,
          adornedEnd: !active ? classes.shortcut : classes.searchFocused,
          adornedStart: active ? classes.searchFocused : '',
        }}
        {...props}
      />
    </>
  );
};

SearchBar.defaultProps = defaultProps as unknown as Partial<Props>;

export default SearchBar;
