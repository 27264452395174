import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';

// MUI functions
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';

import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { Button } from '@material-ui/core';
import { toggleCookieConfiguration } from '@/features/globals/slice';
import Typography from '@/features/markets/components/Typography';
import LanguageDialog from '../ui-components/LanguageSwitcher';
import {
  AppleStoreIcon,
  Diamond,
  FacebookIcon,
  GooglePlayIcon,
  InstagramIcon,
  Language,
  LinkedinIcon,
  LogoWixdom,
  TwitterIcon,
} from '../ui-components/SVGIcons';
import Spacer from '../ui-components/Spacer';
import { Background } from '../ui-components/SVGBackground';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      paddingRight: '10%',
    },
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(5),
  },
  gridContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  columns: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  content: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  logoColumn: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  bottomLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svgBg: {
    position: 'absolute',
    bottom: -10,
    left: 0,
    [theme.breakpoints.up('sm')]: {
      clipPath: 'polygon(0% 40%, 0 100%, 15% 100%)',
    },
    [theme.breakpoints.up('md')]: {
      clipPath: 'polygon(0% 50%, 0 100%, 25% 100%)',
    },
    [theme.breakpoints.up('lg')]: {
      // clipPath (X -> 0, Y -> ??) (X -> 0, Y -> 100%) (X -> ??, Y -> 100%)
      clipPath: 'polygon(0% 45%, 0 100%, 27% 100%)',
    },
  },
  button: {
    padding: 0,
    margin: 0,
    textTransform: 'none',
  },
  links: {
    color: theme.palette.greyScale.superlight,
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

const DATA = [
  /*  {
    title: 'Product',
    contentType: 'column',
    content: ['Insights', 'Markets', 'Community'],
    link: 'https://app.wixdom.io/markets/offerings',
  }, */
  {
    title: 'company',
    contentType: 'column',
    content: ['Collisio Technologies'],
    link: 'https://www.collis.io',
  },
  {
    title: 'ux',
    contentType: 'column',
    content: ['help@collis.io'],
    link: 'mailto:help@collis.io',
  },
  {
    title: 'app',
    contentType: 'logos',
    content: ['apple', 'googlePlay'],
    link: '',
  },
];

const SOCIAL_ITEMS = [
  {
    url: 'https://www.linkedin.com/company/wixdom',
    network: 'linkedin',
  },
  {
    url: 'https://www.facebook.com/Wixdom-101803181566785',
    network: 'facebook',
  },
  {
    url: 'https://www.twitter.com/Wixdom3',
    network: 'twitter',
  },
  {
    url: 'https://www.instagram.com/wixdom.io',
    network: 'instagram',
  },
];

const BottomFooter = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [languageSwitcherOpen, setLanguageSwitcherOpen] = useState(false);

  const renderNetworkIcon = (network: 'facebook' | 'instagram' | 'twitter' | 'apple' | 'googlePlay' | 'linkedin') => {
    switch (network) {
      case 'facebook':
        return <FacebookIcon color={theme.palette.greyScale.superlight} />;
      case 'twitter':
        return <TwitterIcon color={theme.palette.greyScale.superlight} />;
      case 'linkedin':
        return <LinkedinIcon color={theme.palette.greyScale.superlight} />;
      case 'instagram':
        return <InstagramIcon color={theme.palette.greyScale.superlight} />;
      case 'apple':
        return (
          <AppleStoreIcon
            color={theme.palette.greyScale.superlight}
            size={24}
          />
        );
      case 'googlePlay':
        return <GooglePlayIcon size={24} />;
      default:
        return <LinkedinIcon color={theme.palette.greyScale.superlight} />;
    }
  };
  return (
    <>
      <Grid container className={classes.gridRoot}>
        {md! && (
          <div className={classes.svgBg}>
            <Background />
          </div>
        )}
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LogoWixdom
            text={theme.palette.secondary.main}
            color={theme.palette.secondary.main}
          />
        </Grid>
        {!md && <Spacer spacing={10} />}
        <Grid item xs={12} md={8} className={!md ? classes.gridContent : ''}>
          <div className={classes.columns}>
            {DATA.map((item) => (
              <Grid item xs={12} md={3} key={item.title}>
                <Typography
                  variant="baseBold"
                  style={{ color: theme.palette.greyScale.superlight }}
                  className={classes.content}
                >
                  {t(`footer.categories.${item.title}.title`)}
                </Typography>
                <Spacer spacing={0.5} />
                <div
                  className={
                    item.contentType === 'logos' ? classes.logoColumn : ''
                  }
                >
                  {item.content.map((x) => (
                    <div key={x} className={classes.content}>
                      {item.title === 'app' ? (
                        <span style={{ marginRight: theme.spacing(2) }}>
                          {renderNetworkIcon(x)}
                        </span>
                      ) : (
                        <a href={item.link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                          <Typography
                            variant="smallBold"
                            style={{
                              color: theme.palette.greyScale.superlight,
                              marginTop: theme.spacing(1),
                              cursor: 'pointer',

                            }}
                          >
                            {x}
                          </Typography>
                        </a>
                      )}
                    </div>
                  ))}
                </div>
                {!md && <Spacer spacing={5} />}
              </Grid>
            ))}
          </div>
          {md && <Spacer spacing={3} />}
          {!md && (
            <>
              <Button
                style={{ display: 'flex' }}
                className={classes.button}
                onClick={() => setLanguageSwitcherOpen(true)}
              >
                <Language color={theme.palette.greyScale.superlight} />
                <Typography
                  variant="smallBold"
                  style={{
                    color: theme.palette.greyScale.superlight,
                    marginLeft: theme.spacing(1),
                  }}
                >
                  {t('currentLanguage')}
                </Typography>
              </Button>
              <Spacer spacing={3} />
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {SOCIAL_ITEMS.map((socialItem) => (
              <a
                key={socialItem.url}
                href={socialItem.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  marginLeft: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                }}
              >
                {renderNetworkIcon(socialItem.network)}
              </a>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderTop: `1px solid ${theme.palette.greyScale.light}`,
              paddingTop: theme.spacing(2),
            }}
          >
            {md && (
              <Button
                style={{ display: 'flex' }}
                className={classes.button}
                onClick={() => setLanguageSwitcherOpen(true)}
              >
                <Language color={theme.palette.greyScale.superlight} />
                <Typography
                  variant="smallBold"
                  style={{
                    color: theme.palette.greyScale.superlight,
                    marginLeft: theme.spacing(1),
                  }}
                >
                  {t('currentLanguage')}
                </Typography>
              </Button>
            )}
            <div className={classes.bottomLine}>
              <Link href="/legal/privacy-policy" passHref>
                <Typography
                  component="a"
                  variant={sm ? 'extraSmall2bold' : 'smallBold'}
                  style={{
                    color: theme.palette.greyScale.superlight,
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {t('footer.legal.privacy')}
                </Typography>
              </Link>
              <div
                style={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2),
                }}
              >
                <Diamond
                  color={theme.palette.secondary.main}
                  size={sm ? 13 : 17}
                />
              </div>

              <Link href="/legal/terms" passHref>
                <Typography
                  component="a"
                  variant={sm ? 'extraSmall2bold' : 'smallBold'}
                  style={{
                    color: theme.palette.greyScale.superlight,
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {t('footer.legal.note')}
                </Typography>
              </Link>

              <div
                style={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2),
                }}
              >
                <Diamond
                  color={theme.palette.secondary.main}
                  size={sm ? 13 : 17}
                />
              </div>
              <Button
                onClick={() => dispatch(toggleCookieConfiguration())}
                classes={{ root: classes.button }}
              >
                <Typography
                  variant={sm ? 'extraSmall2bold' : 'smallBold'}
                  style={{ color: theme.palette.greyScale.superlight }}
                >
                  {t('footer.changePrivacySettings')}
                </Typography>
              </Button>
              <div
                style={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2),
                }}
              >
                <Diamond
                  color={theme.palette.secondary.main}
                  size={sm ? 13 : 17}
                />
              </div>

              <Link href="/legal/cookie-policy" passHref>
                <Typography
                  component="a"
                  variant={sm ? 'extraSmall2bold' : 'smallBold'}
                  style={{
                    color: theme.palette.greyScale.superlight,
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {t('footer.legal.cookies')}
                </Typography>
              </Link>
            </div>
          </div>
          <Spacer spacing={2} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              variant={sm ? 'extraSmall2bold' : 'smallBold'}
              style={{ color: theme.palette.greyScale.superlight }}
            >
              &copy; 2020-
              {new Date().getFullYear()}
              {' '}
              COLLISIO TECHNOLOGIES S.L.
            </Typography>
          </div>
          <Spacer spacing={sm ? 10 : 2} />
        </Grid>
      </Grid>
      <LanguageDialog
        onClose={() => setLanguageSwitcherOpen(false)}
        open={languageSwitcherOpen}
      />
    </>
  );
};

export default BottomFooter;
