import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: ({ spacing }: StyleProps) => theme.spacing(spacing),
  },
}));

interface StyleProps {
  spacing: number
}
interface Props {
  spacing: number
}

const defaultProps = {

};

const Spacer = ({ spacing }: Props) => {
  const classes = useStyles({ spacing });
  return (
    <div className={classes.root} />
  );
};

Spacer.defaultProps = defaultProps as unknown as Partial<Props>;

export default Spacer;
