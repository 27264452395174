/* eslint-disable jsx-quotes */
/* eslint-disable @typescript-eslint/quotes */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'next-i18next';

import theme from 'src/theme';
import useKFormatter from '@/hooks/useKFormatter';
import Timeline from './Timeline';
import Typography from '@/features/markets/components/Typography';
import Spacer from '../ui-components/Spacer';
import Timeline2 from './Timeline2';

const useStyles = makeStyles(() => ({
  goal: {
    display: 'flex',
    flexDirection: 'column',
  },
  line: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid  ${theme.palette.greyScale.light}`,
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  moneyData: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
  },
  green: {
    color: 'green',
  },
  lastRow: {
    display: 'flex',
    marginTop: theme.spacing(2),
    maxHeight: '50px',
    marginBottom: theme.spacing(1),
  },
  lastRowFeed: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    maxHeight: '50px',
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  feed: boolean;
  raised: number | null;
  fundingGoalMin: number | null;
  fundingGoalMax: number | null;
  minTicket: number | null;
  investorsCount: number | null;
  daysRemaining: number | null;
}

const defaultProps = {};

const CardMoneyInformation = ({
  raised,
  fundingGoalMin,
  fundingGoalMax,
  minTicket,
  investorsCount,
  daysRemaining,
  feed,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('markets');
  const md = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Grid item container className={classes.data}>
        <Grid
          item
          style={{ marginTop: theme.spacing(1) }}
          className={classes.moneyData}
        >
          <Typography variant="base2">
            {t('markets.detailView.summary.totalRaised')}
          </Typography>
          <Typography variant="base2">
            {t('markets.detailView.summary.fundingGoal')}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: -6 }} className={classes.moneyData}>
          <Typography
            variant="subheader"
            style={{ marginTop: theme.spacing(0.5) }}
          >
            {/* {moneyToString(offering.raised || 0)} */}
            {`$${useKFormatter(raised || 0)}`}
          </Typography>
          <Typography
            variant="subheader"
            style={{ marginTop: theme.spacing(0.5) }}
          >
            <span
              className={
                raised && 100 * (raised / (fundingGoalMin || 1)) > 100
                  ? classes.green
                  : undefined!
              }
            >
              $
              {useKFormatter(fundingGoalMin || 0)}
            </span>
            <span
              className={
                raised && 100 * (raised / (fundingGoalMax || 1)) >= 100
                  ? classes.green
                  : undefined!
              }
            >
              - $
              {useKFormatter(fundingGoalMax || 0)}
              {' '}
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: '-45px', marginBottom: '-45px' }}>
        <Timeline2 value={raised || 0} goal={fundingGoalMax!} fundingGoalMin={fundingGoalMin!} />
      </Grid>
      <div className={feed ? classes.lastRowFeed : classes.lastRow}>
        <div
          className={classes.line}
          style={md ? { flexBasis: '40%' } : { flexBasis: '35%' }}
        >
          <Typography variant={!md ? 'secondary2' : 'small2'}>
            {t('markets.detailView.summary.minimumTicket')}
          </Typography>
          <Spacer spacing={0.5} />
          <Typography variant="subheader" style={{ marginTop: -6 }}>
            $
            {useKFormatter(minTicket! || 0)}
          </Typography>
        </div>
        <div className={classes.line} style={{ flexBasis: '30%' }}>
          <Typography variant={!md ? 'secondary2' : 'small2'}>
            {t('markets.detailView.summary.investors')}
          </Typography>
          <Spacer spacing={0.5} />

          <Typography variant="subheader" style={{ marginTop: -6 }}>
            {investorsCount || '-'}
          </Typography>
        </div>
        <div style={{ flexBasis: '30%' }}>
          <Typography variant={!md ? 'secondary2' : 'small2'}>
            {t('markets.offeringList.daysRemaining')}
          </Typography>
          <Spacer spacing={0.5} />

          <Typography variant="subheader" style={{ marginTop: -6 }}>
            {(daysRemaining || 0) > 0
              ? daysRemaining
              : t('markets.detailView.status.closed')}
          </Typography>
        </div>
      </div>
    </>
  );
};

CardMoneyInformation.defaultProps = (defaultProps as unknown) as Partial<Props>;

export default CardMoneyInformation;
