/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-new-wrappers */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OfferingListInfo, OfferingType } from 'typings/markets/api';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
  Card, Grid, IconButton, useMediaQuery,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import { FeedOfferingItem } from 'typings/feed/state';
import theme from 'src/theme';
import CardMoneyInformation from '@/components/offering-card/MonetaryInformation';
import { DEFAULT_OFFER_COVER_IMAGES } from '@/constants';
import { toggleAddWatchlist } from '@/features/markets/api/slice';
import { AppDispatch } from '@/store/store';
import CardLink from '../links/CardLink';
import Typography from '@/features/markets/components/Typography';

interface StyleProps {
  bgImg: string;
}

interface StyleProps {
  bgImg: string;
  feed: boolean;
}
const useStyles = makeStyles(() => ({
  container: {
    marginTop: (props: StyleProps) => (props.feed ? 0 : theme.spacing(1.5)),
    marginBottom: theme.spacing(1.5),
    height: '100%',
  },
  right: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1.5),
    },
  },
  card: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    cursor: 'pointer',
    // maxWidth: '400px',
    // minHeight: '370px',
  },
  cardFeed: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
    cursor: 'pointer',
  },
  image: {
    display: 'flex',
    alignContent: 'space-between',
    height: '198px',
    flexBasis: '198px',
    backgroundImage: ({ bgImg }: StyleProps) => `url(${bgImg})`,
    backgroundColor: (props: StyleProps) => (props.bgImg ? 'transparent' : theme.palette.primary.extralight),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  favoriteIconToggled: {
    color: theme.palette.error.main,
  },
  newOffer: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    backgroundColor: theme.palette.positive.superlight,
    color: theme.palette.positive.main,
    borderRadius: '4px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  information: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: 'inherit',
  },
  category: {
    backgroundColor: theme.palette.primary.extralight,
    borderRadius: '4px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
  },
  xsLogo: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.greyScale.superlight,
    marginBottom: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  cardInformation: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexGrow: 1,
    marginBottom: theme.spacing(-2),
  },
  firstRowFeed: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  /* companyName: {
    flexGrow: 2,
  }, */
  iconRoot: {
    padding: theme.spacing(0),
  },
}));

interface PropsDetails {
  isNew: boolean;
  slug: string;
  category: string;
  roundedPlatformLogo: string | null;
  companyName: string;
  hasUserSubscribed: boolean;
  raised: number | null;
  fundingGoalMin: number | null;
  fundingGoalMax: number | null;
  minTicket: number | null;
  investorsCount: number | null;
  daysRemaining: number | null;
  offerImage: string | null;
  feed: boolean;
  tagline: string | null;
  onToggleFavorites: (arg0: React.MouseEvent<HTMLButtonElement>) => void;
}

export const OfferingCard = ({
  feed,
  slug,
  isNew,
  category,
  tagline,
  roundedPlatformLogo,
  companyName,
  hasUserSubscribed,
  raised,
  fundingGoalMin,
  fundingGoalMax,
  minTicket,
  investorsCount,
  daysRemaining,
  offerImage,
  onToggleFavorites,
}: PropsDetails) => {
  const classes = useStyles({
    bgImg: offerImage || DEFAULT_OFFER_COVER_IMAGES.xs,
    feed,
  });
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('markets');

  return (
    <Card className={feed ? classes.cardFeed : classes.card}>
      <CardLink href={`/markets/offerings/${slug}/`} style={{ height: '100%' }}>
        <div className={classes.card}>
          {/* IMAGE */}
          <Grid item container xs={12} className={classes.image}>
            {isNew ? (
              <Grid item className={classes.newOffer}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: theme.palette.positive.main,
                  }}
                  variant="secondary2medium"
                >
                  NEW
                </Typography>
              </Grid>
            ) : (
              <div style={{ width: '100%' }} />
            )}
            <div className={classes.information}>
              {category ? (
                <div className={classes.category}>
                  <Typography
                    variant={md ? 'small2bold' : 'base2bold'}
                    color="primary"
                  >
                    {t(`markets.filters.sectors.${category}`).toLowerCase()}
                  </Typography>
                </div>
              ) : (
                <div style={{ backgroundColor: 'transparent' }} />
              )}
              <Grid item className={classes.xsLogo}>
                <img src={roundedPlatformLogo || ''} alt="Platform Logo" />
              </Grid>
            </div>
          </Grid>
          {/* CARD INFORMATION */}
          <Grid item container className={classes.cardInformation}>
            {/* TITLE + DESCRPTION */}
            <Grid
              item
              className={feed ? classes.firstRowFeed : classes.firstRow}
            >
              <div>
                <Typography variant="subheader" className={classes.companyName}>
                  {companyName}
                </Typography>
                <Typography variant="secondary2" style={{ color: theme.palette.greyScale.dark }}>
                  {tagline}
                </Typography>
              </div>
              <IconButton
                onClick={onToggleFavorites}
                classes={{ root: classes.iconRoot }}
              >
                <FavoriteIcon
                  className={
                    hasUserSubscribed ? classes.favoriteIconToggled : undefined
                  }
                />
              </IconButton>

            </Grid>
            <CardMoneyInformation
              raised={raised}
              fundingGoalMin={fundingGoalMin}
              fundingGoalMax={fundingGoalMax}
              minTicket={minTicket}
              investorsCount={investorsCount}
              daysRemaining={daysRemaining}
              feed={feed}
            />
          </Grid>
        </div>
      </CardLink>
    </Card>
  );
};

interface Props {
  offering: OfferingListInfo | OfferingType | FeedOfferingItem;
  last?: boolean;
  feed: boolean;
}
const defaultProps = {
  type: 'watchlist',
  last: false,
  feed: false,
  isSearch: false,
};
const OfferingCardController = ({ offering, last, feed }: Props) => {
  const dispatch = useDispatch() as AppDispatch;

  const classes = useStyles({
    bgImg: offering.images?.offer_image?.xs || DEFAULT_OFFER_COVER_IMAGES.xs,
    feed,
  });

  const onToggleFavorites = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(toggleAddWatchlist({ slug: offering.slug }));
  };

  return (
    <div
      className={
        last ? classes.container : clsx(classes.container, classes.right)
      }
    >
      <div
        // href={`/markets/offerings/${offering.slug}/`}
        style={{ height: '100%', padding: 0 }}
        // onCallback={onCallback}
        // type={type}
        // setActive={setActive}
        // card
      >
        <OfferingCard
          slug={offering.slug}
          tagline={offering.summary?.tagline}
          raised={offering.raised}
          offerImage={offering.images?.offer_image?.xs}
          fundingGoalMin={offering.summary.funding_goal_min}
          fundingGoalMax={offering.summary.funding_goal_max}
          minTicket={offering.ticket_amount_minimum}
          investorsCount={offering.investors_count}
          daysRemaining={offering.days_remaining}
          isNew={offering.is_new}
          category={offering.industry_category[0]}
          roundedPlatformLogo={offering.platform.rounded_logo}
          companyName={offering.company_name}
          hasUserSubscribed={offering.has_user_subscribed}
          feed={feed}
          onToggleFavorites={onToggleFavorites}
        />
      </div>
    </div>
  );
};

OfferingCardController.defaultProps = (defaultProps as unknown) as Partial<Props>;

export default OfferingCardController;
