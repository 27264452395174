import React from 'react';
import Link, { LinkProps } from 'next/link';
import { makeStyles, darken } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { ArrowRightAlt } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  featuredBtn: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.success.main, 0.15),
    },
  },
}));

export type ButtonLinkProps = Omit<ButtonProps, 'href' | 'classes'> &
Pick<LinkProps, 'href' | 'as' | 'prefetch'>;

interface Props {
  button: string,
  appearance: 'primary' | 'secondary-outlined' | 'secondary' | 'primary-outlined' | 'featured'
  compact: boolean
}

const ButtonLink = React.forwardRef<ButtonLinkProps, any>(
  ({
    href, as, prefetch, appearance, ...props
  }: Props & ButtonLinkProps, ref: React.ForwardedRef<any>) => {
    const classes = useStyles();
    return (
      <Link href={href} as={as} prefetch={prefetch} passHref>
        <Button
          ref={ref}
          color={appearance.startsWith('primary') ? 'primary' : 'secondary'}
          variant={appearance.endsWith('Outlined') ? 'outlined' : 'contained'}
          className={appearance === 'featured' ? classes.featuredBtn : undefined}
          endIcon={appearance === 'featured' ? <ArrowRightAlt /> : undefined}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Link>
    );
  },
);

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
